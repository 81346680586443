var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-container',{staticClass:"createaccount-page pb-12"},[_c('v-card',{staticClass:"mx-auto mt-10",attrs:{"max-width":"400","elevation":"1"}},[_c('v-card-title',{staticClass:"text-center"},[_c('h1',{staticClass:"display-1 mx-auto mb-5"},[_vm._v(_vm._s(_vm.$t("CreateDiary")))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"autocomplete":"off"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-row',[_c('v-col',{staticClass:"form-row",attrs:{"xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"autocorrect":"off","spellcheck":"false","autocomplete":"off","rules":[
                  function (v) { return !!v || _vm.$t('DiaryTitleIsMissing'); },
                  function (v) { return (v && v.length <= 45) || _vm.$t('DiaryTitleMaxLength'); },
                  function (v) { return (v && v.length >= 3) || _vm.$t('DiaryTitleToShort'); } ],"disabled":_vm.is_saving,"label":_vm.$t('Title'),"required":""},model:{value:(_vm.diary.title),callback:function ($$v) {_vm.$set(_vm.diary, "title", $$v)},expression:"diary.title"}})],1),_c('v-col',{staticClass:"form-row",attrs:{"xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"autocorrect":"off","spellcheck":"false","autocomplete":"off","rules":[
                  function (v) { return !!v || _vm.$t('DiaryNameIsMissing'); },
                  function (v) { return (v && v.length <= 45) || _vm.$t('DiaryNameMaxLength'); },
                  function (v) { return (v && v.length >= 3) || _vm.$t('DiaryNameToShort'); },
                  function (v) { return /^[a-zA-Z0-9_.]+$/.test(v) || _vm.$t('DiaryNameNotValid'); } ],"disabled":_vm.is_saving,"label":_vm.$t('DiaryName'),"required":"","hint":_vm.host + _vm.diary.name,"persistent-hint":""},model:{value:(_vm.diary.name),callback:function ($$v) {_vm.$set(_vm.diary, "name", $$v)},expression:"diary.name"}})],1),_c('v-col',{staticClass:"form-row",attrs:{"xs":"12","cols":"12"}},[_c('v-select',{attrs:{"items":[
                  {
                    text: _vm.$t('OnlyForMe'),
                    value: _vm.$enums.DIARY_PRIVACY.OnlyForMe,
                  },
                  {
                    text: _vm.$t('OpenForAll'),
                    value: _vm.$enums.DIARY_PRIVACY.OpenForAll,
                  },
                  {
                    text: _vm.$t('OnlyForMembers'),
                    value: _vm.$enums.DIARY_PRIVACY.OnlyForMembers,
                  } ],"disabled":_vm.is_saving,"required":"","label":_vm.$t('Availability')},model:{value:(_vm.diary.privacy),callback:function ($$v) {_vm.$set(_vm.diary, "privacy", $$v)},expression:"diary.privacy"}})],1),_c('v-col',{attrs:{"xs":"12","cols":"12"}},[_c('v-btn',{staticClass:"text-none",attrs:{"block":"","loading":_vm.is_saving,"color":"primary"},on:{"click":_vm.sendForm}},[_vm._v(_vm._s(_vm.$t("Button.Send")))])],1),(_vm.error && !_vm.is_saving)?_c('v-col',{attrs:{"xs":"12","cols":"12"}},[_c('v-alert',{attrs:{"text":"","prominent":"","type":"error","icon":"$vuetify.icons.cloudAlert"}},[_c('p',[_vm._v(_vm._s(_vm.error))])])],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }