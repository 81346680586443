<template>
  <div class="fill-height">
    <v-container class="createaccount-page pb-12">
      <v-card max-width="400" class="mx-auto mt-10" elevation="1">
        <v-card-title class="text-center">
          <h1 class="display-1 mx-auto mb-5">{{ $t("CreateDiary") }}</h1>
        </v-card-title>

        <v-card-text>
          <v-form v-model="form" ref="form" autocomplete="off">
            <v-row>
              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="diary.title"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  :rules="[
                    (v) => !!v || $t('DiaryTitleIsMissing'),
                    (v) => (v && v.length <= 45) || $t('DiaryTitleMaxLength'),
                    (v) => (v && v.length >= 3) || $t('DiaryTitleToShort'),
                  ]"
                  :disabled="is_saving"
                  :label="$t('Title')"
                  required
                ></v-text-field>
              </v-col>
              <v-col xs="12" cols="12" class="form-row">
                <v-text-field
                  v-model="diary.name"
                  autocorrect="off"
                  spellcheck="false"
                  autocomplete="off"
                  :rules="[
                    (v) => !!v || $t('DiaryNameIsMissing'),
                    (v) => (v && v.length <= 45) || $t('DiaryNameMaxLength'),
                    (v) => (v && v.length >= 3) || $t('DiaryNameToShort'),
                    (v) =>
                      /^[a-zA-Z0-9_.]+$/.test(v) || $t('DiaryNameNotValid'),
                  ]"
                  :disabled="is_saving"
                  :label="$t('DiaryName')"
                  required
                  :hint="host + diary.name"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col xs="12" cols="12" class="form-row">
                <v-select
                  v-bind:items="[
                    {
                      text: $t('OnlyForMe'),
                      value: $enums.DIARY_PRIVACY.OnlyForMe,
                    },
                    {
                      text: $t('OpenForAll'),
                      value: $enums.DIARY_PRIVACY.OpenForAll,
                    },
                    {
                      text: $t('OnlyForMembers'),
                      value: $enums.DIARY_PRIVACY.OnlyForMembers,
                    },
                  ]"
                  v-model="diary.privacy"
                  :disabled="is_saving"
                  required
                  :label="$t('Availability')"
                ></v-select>
              </v-col>

              <v-col xs="12" cols="12">
                <v-btn
                  block
                  :loading="is_saving"
                  color="primary"
                  @click="sendForm"
                  class="text-none"
                  >{{ $t("Button.Send") }}</v-btn
                >
              </v-col>

              <v-col xs="12" cols="12" v-if="error && !is_saving">
                <v-alert
                  text
                  prominent
                  type="error"
                  icon="$vuetify.icons.cloudAlert"
                >
                  <p>{{ error }}</p>
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "CreateDiary": "Create diary",
    "PageDescription": "Create your own diary for free on Babiry and start sharing with family and friends.",
    "DiaryTitleMaxLength": "Title must be less than 45 characters",
    "DiaryTitleToShort": "Title to short. At least 3 characters",
    "DiaryNameMaxLength": "Address must be less than 45 characters",
    "DiaryNameToShort": "Address to short. At least 3 characters",
    "DiaryNameNotValid": "Address not valid. Use a-zA-Z0-9_.",
    "Title": "Title",
    "DiaryTitleIsMissing": "Title is missing!",
    "DiaryName": "Diary address",
    "DiaryNameIsMissing": "Address is missing!",
    "OnlyForMe": "Only for me",
    "OpenForAll": "Open for all",
    "OnlyForMembers": "Only open for members",
    "Availability": "Availability"
     },
    "sv": {
    "CreateDiary": "Skapa dagbok",
    "PageDescription": "Skapa en egen dagbok helt gratis på Barndagboken och börja dela med dig till familj och vänner.",
    "DiaryTitleMaxLength": "Titel får inte vara längre än 45 tecken",
    "DiaryTitleToShort": "Title to short. At least 3 characters",
    "DiaryNameMaxLength": "Adressen får inte vara längre än 45 tecken",
    "DiaryNameToShort": "Adressen för kort. Minst 3 tecken",
    "DiaryNameNotValid": "Adressen är inte korrekt. Använd a-zA-Z0-9_.",
    "Title": "Titel",
    "DiaryTitleIsMissing": "Titel saknas!",
    "DiaryName": "Dagboksadress",
    "DiaryNameIsMissing": "Adress saknas!",
    "Availability": "Tillgänglig",
    "OnlyForMe": "Enbart öppen för mig",
    "OpenForAll": "Öppen för alla",
    "OnlyForMembers": "Öppen för inloggade användare"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import { responseHelpers } from "@/_helpers";

export default {
  name: "create_diary",

  metaInfo() {
    return {
      title: this.$t("CreateDiary"),
      meta: [
        {
          property: "og:title",
          content: this.$t("CreateDiary") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
        {
          property: "og:description",
          content: this.$t("PageDescription"),
          vmid: "og:description",
        },
        {
          name: "description",
          content: this.$t("PageDescription"),
          vmid: "description",
        },
      ],
    };
  },
  data: () => ({
    error: null,
    form: false,
    is_saving: false,
    host: "",
    diary: {
      name: "",
      title: "",
      privacy: "1",
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created: function () {
    if (this.user && this.user.diaries && this.user.diaries.length > 0) {
      for (var i = 0; i < this.user.diaries.length; i++) {
        if (this.user.diaries[i].roles.indexOf(1) > -1) {
          this.$router.push("/");
          return;
        }
      }
    }
    this.diary.privacy = this.$enums.DIARY_PRIVACY.OpenForAll;
    this.host = document.location.hostname + "/";
  },
  methods: {
    ...mapActions({
      create: "account/createDiary",
    }),
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_saving = true;

        self
          .create(self.diary)
          .then(function () {
            self.$router.push("/");
          })
          .catch(function (error) {
            self.error = responseHelpers.errorTextFromResponse(error);
            self.is_saving = false;
          });
      }
    },
  },
};
</script>

<style>
.form-row {
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>